import { useCallback, useContext, useState } from 'react';
import { SimplifiedEvent } from 'src/types/events';
import { BasicFormValues, CreationForm } from 'src/types/ordersCreation';
import { OrderType } from '@models/orders';
import { getMissingRequiredFields } from '@utils/ordersCreation';
import { requiredFields } from '@routes/ordersCreation/config';
import { OrdersCreationContext } from '@routes/ordersCreation/components';

const onlyNumbersAllowedRegExp = new RegExp('^([1-9][0-9]*)?$');

export const useSingleOrderCreationForm = (
  orderForm: CreationForm['forms'][number],
  contractIdentifier: string,
) => {
  const { setValue } = useContext(OrdersCreationContext);

  const [missingRequiredFields, setMissingRequiredFields] = useState<(keyof BasicFormValues)[]>([]);

  const revalidateField = useCallback(
    (field: keyof BasicFormValues, value: string | OrderType) => {
      if (!value) {
        if (
          missingRequiredFields.includes(field) ===
          requiredFields[orderForm.values.orderType].includes(field)
        )
          return;

        setMissingRequiredFields(prev => [...prev, field]);
        return;
      }

      if (!missingRequiredFields.includes(field)) return;

      setMissingRequiredFields(prev =>
        value ? prev.filter(missingField => missingField !== field) : [...prev, field],
      );
    },
    [missingRequiredFields, orderForm],
  );

  const setLocationPointAdditionalInfo = useCallback(
    (ev: SimplifiedEvent<string>) => {
      setValue(
        'locationPointAdditionalInfo',
        ev.target.value,
        orderForm.formIdentifier,
        contractIdentifier,
      );
    },
    [setValue],
  );

  const setContactPersonId = useCallback(
    (id: string) => {
      setValue('contactPersonId', id, orderForm.formIdentifier, contractIdentifier);
    },
    [setValue],
  );

  const setOrderLevelReference = useCallback(
    (ev: SimplifiedEvent<string>) => {
      setValue(
        'orderLevelReference',
        ev.target.value,
        orderForm.formIdentifier,
        contractIdentifier,
      );
    },
    [setValue],
  );

  const setSelectedOrderType = useCallback(
    (ev: SimplifiedEvent<string>) => {
      setValue('orderType', ev.target.value, orderForm.formIdentifier, contractIdentifier);

      if (orderForm.values.orderType === 'DELIVERY')
        setValue('estimatedWasteQuantity', '', orderForm.formIdentifier, contractIdentifier);

      revalidateField('orderType', ev.target.value);
    },
    [setValue, revalidateField],
  );

  const setEquipmentQuantity = useCallback(
    (ev: SimplifiedEvent<string>) => {
      if (!onlyNumbersAllowedRegExp.test(ev.target.value)) return;

      setValue('equipmentQuantity', ev.target.value, orderForm.formIdentifier, contractIdentifier);

      revalidateField('equipmentQuantity', ev.target.value);
    },
    [setValue, revalidateField],
  );

  const setEstimatedWasteQuantity = useCallback(
    (ev: SimplifiedEvent<string>) => {
      if (!onlyNumbersAllowedRegExp.test(ev.target.value)) return;

      setValue(
        'estimatedWasteQuantity',
        ev.target.value,
        orderForm.formIdentifier,
        contractIdentifier,
      );

      revalidateField('estimatedWasteQuantity', ev.target.value);
    },

    [setValue, revalidateField],
  );

  const setComments = useCallback(
    (ev: SimplifiedEvent<string>) => {
      setValue('comments', ev.target.value, orderForm.formIdentifier, contractIdentifier);

      revalidateField('comments', ev.target.value);
    },
    [setValue, revalidateField],
  );

  const setSpecifiedWasteDescription = useCallback(
    (ev: SimplifiedEvent<string>) =>
      setValue(
        'specifiedWasteDescription',
        ev.target.value,
        orderForm.formIdentifier,
        contractIdentifier,
      ),
    [setValue],
  );

  const validate = useCallback(() => {
    const missingFields = getMissingRequiredFields(
      orderForm.values,
      requiredFields[orderForm.values.orderType],
    );
    setMissingRequiredFields(missingFields);

    return missingFields.length === 0;
  }, [requiredFields, orderForm.values]);

  const isFieldRequired = useCallback(
    (field: keyof BasicFormValues) => requiredFields[orderForm.values.orderType].includes(field),
    [orderForm.values.orderType],
  );

  return {
    missingRequiredFields,
    setSelectedOrderType,
    setEquipmentQuantity,
    setEstimatedWasteQuantity,
    setComments,
    setSpecifiedWasteDescription,
    setLocationPointAdditionalInfo,
    setOrderLevelReference,
    setContactPersonId,
    validate,
    isFieldRequired,
  };
};
