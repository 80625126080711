import { equipmentServiceTypesToOrderTypesMap } from '@config/order';
import { RadioProps } from '@fortum/elemental-ui';
import { OrderType, orderTypes } from '@models/orders';
import { isEquipmentServiceType } from '@utils/contract';
import { t } from 'i18next';
import { uniq, flatten } from 'lodash';
import {
  FormValues,
  BasicFormValues,
  CreationForms,
  FormRequiredFields,
} from 'src/types/ordersCreation';

export * from './forms';

export const mapOrderTypesToRadioItems = (orderTypes: OrderType[]): RadioProps[] =>
  orderTypes.map(orderType => ({
    label: t(`orderCreation:form.orderType.${orderType}`),
    value: orderType,
  }));

export const mapRawEquipmentServiceTypesToOrderTypes = (rawEquipmentServiceTypes: string[]) =>
  uniq(
    flatten(
      rawEquipmentServiceTypes
        .map(rawEquipmentServiceType => rawEquipmentServiceType.toUpperCase().replace(/-/g, '_'))
        .filter(isEquipmentServiceType)
        .map(eqServiceType => equipmentServiceTypesToOrderTypesMap[eqServiceType]),
    ),
  );

export const orderTypesSortingFunction =
  (sortingOrder: OrderType[]) => (a: OrderType, b: OrderType) =>
    sortingOrder.indexOf(a) - sortingOrder.indexOf(b);

export const isOrderType = (value: string): value is OrderType =>
  orderTypes.includes(value as OrderType);

export const getMissingRequiredFields = (
  formValues: FormValues,
  requiredFields: (keyof BasicFormValues)[],
) =>
  requiredFields.filter(field =>
    typeof formValues[field] === 'string' ? !formValues[field]?.trim() : !formValues[field],
  );

export const countAllMissingRequiredFields = (
  creationForms: CreationForms,
  requiredFields: FormRequiredFields,
) =>
  creationForms
    .flatMap(creationForm =>
      creationForm.forms.map(
        orderForm =>
          getMissingRequiredFields(orderForm.values, requiredFields[orderForm.values.orderType])
            .length,
      ),
    )
    .reduce((prev, current) => prev + current, 0);

export const isContractsFetchingEnabled = (
  selectedSites: string[],
  selectedWasteTypes: string[],
  selectedEquipmentTypes: string[],
  search: string,
) =>
  selectedSites.length > 0 ||
  search.trim().length > 0 ||
  selectedWasteTypes.length > 0 ||
  selectedEquipmentTypes.length > 0;

export const createServiceTileId = (contractIdentifier: string) =>
  `service-tile-${contractIdentifier}`;
